import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import useQuery from "./useQuery";
import config from "../../configs";
import { createSocketUser } from "../../configs/socketConnectivity";
import { Notification, toast } from "components/ui";
import { useNavigate } from "react-router-dom";
import { encryptObject } from "../../lib/cryptoJS";
import DefaultUserImage from "../../assets/User.png";
function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const saveKeepme = async (reqData) => {
    let Userdetail = reqData;

    if (Userdetail) {
      let details = await encryptObject(Userdetail && Userdetail);
      localStorage.setItem("details", details);
    }
  };

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async ({
    email,
    password,
    roleType,
    emailotp,
    remember,
    twoFACode,
    rememberdata,
    loginHistory,
  }) => {
    try {
      const resp = await apiSignIn({
        email,
        password,
        roleType,
        emailotp,
        remember,
        twoFACode,
        rememberdata,
        loginHistory,
      });

      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.result) {
          createSocketUser(resp.data.result.userId);
          dispatch(
            setUser({
              avatar: resp.data.result.Photofile
                ? config.API_URL +
                  "/images/profile/" +
                  resp.data.result.Photofile
                : DefaultUserImage,
              userName: resp.data.result.firstName
                ? resp.data.result.firstName
                : "",
              authority: ["user"],
              email: resp.data.result.email,
              userId: resp.data.result.userId,
            })
          );
        }

        if (rememberdata) {
          let userDetail = {
            email,
            password,
            rememberdata,
            loginHistory,
            roleType,
            resendOTP: false,
          };
          await saveKeepme(userDetail);
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        toast.push(
          <Notification
            title={"Logged In successfully"}
            type="success"
            duration={1000}
          />,
          {
            placement: "top-center",
          }
        );
        navigate("/dashboard");
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: errors?.response?.data?.status,
        message: errors?.response?.data?.message,
        errors: errors?.response?.data?.errors,
      };
    }
  };

  const handleSignOut = async () => {
    await dispatch(setUser(initialState));
    await dispatch(onSignOutSuccess());
  };

  const signOut = async () => {
    try {
      // await apiSignOut();
      // alert();
      await handleSignOut();
      window.location.reload(true);
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
