import key from "./index";
const appConfig = {
  apiPrefix: `${key.API_URL}/api`,
  // authenticatedEntryPath: '/app/sales/dashboard',
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/app/account/kyc-form",
  enableMock: true,
};

export default appConfig;
