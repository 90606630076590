let key = {};
// if (process.env.REACT_APP_MODE === "production") {

// } else
console.log("nooooooooooooo", process.env.NODE_ENV);
if (process.env.NODE_ENV === "production") {
  console.log("Set dev env Config");
  // /demooooooo
  // const API_URL = "https://kazhapi.alwin.io";
  // key = {
  //   secretOrKey: "FxUum76z",
  //   CRYPTO_SECRET_KEY: "1234567812345678",
  //   RECAPTCHA_SITE_KEY: "6LcrpOwiAAAAAOXKMtkD74O4bQTegwGnKVckaLjF", //Amplify key
  //   // RECAPTCHA_SITE_KEY: "6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi",
  //   API_URL: "https://kazhapi.alwin.io",
  //   FRONT_URL: "https://kazh.alwin.io/",
  //   ADMIN_URL: "https://kazhcontrols.alwin.io",
  //   SOCKET_URL: "https://kazhapi.alwin.io",
  //   getGeoInfo: "https://ipapi.co/json/",
  //   AUTHENTICATOR_URL: {
  //     PLAY_STORE:
  //       "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
  //     APP_STORE:
  //       "https://apps.apple.com/us/app/google-authenticator/id388497605",
  //   },
  // };

  // Live
  const API_URL = "https://walletapi.kazh.com";
  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LcrpOwiAAAAAOXKMtkD74O4bQTegwGnKVckaLjF", //Amplify key
    // RECAPTCHA_SITE_KEY: "6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi",
    API_URL: "https://walletapi.kazh.com",
    FRONT_URL: "https://wallet.kazh.com/",
    ADMIN_URL: "https://walletcontrols.kazh.com",
    SOCKET_URL: "https://walletapi.kazh.com",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else if (process.env.NODE_ENV == "demo") {
  console.log("Set demo env Config");
  const API_URL = "https://kazhapi.alwin.io";
  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LcrpOwiAAAAAOXKMtkD74O4bQTegwGnKVckaLjF", //Amplify key
    // RECAPTCHA_SITE_KEY: "6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi",
    API_URL: "https://kazhapi.alwin.io",
    FRONT_URL: "https://kazh.alwin.io/",
    ADMIN_URL: "https://kazhcontrols.alwin.io",
    SOCKET_URL: "https://kazhapi.alwin.io",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost"; //http://192.168.29.63:3000/  http://localhost
  // const API_URL = "http://192.168.43.106";
  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LdmsvIfAAAAAMdy31cmaXU4ZKaXcig1yJqr3-VR",
    API_URL: `${API_URL}:2053`,
    SOCKET_URL: `${API_URL}:2053`,
    FRONT_URL: "http://localhost:3000/",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/mudra",
      twitterUrl: "https://www.twitter.com/mudra",
      linkedinUrl: "https://www.linkedin.com/mudra",
      redditUrl: "https://www.reddit.com/mudra",
      youtubeUrl: "https://www.youtube.com/mudra",
    },
    exchangeurl: "http://localhost:3002/home/",
    zaakpayurl: "https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "fb2016ffd3a64b2994a6289dc2b671a4",
    returnUrl: "http://localhost:2053/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "0678056d96914a8583fb518caf42828a",
  };
}

export default key;

// 6LdmsvIfAAAAAPmGU03DL0Kfpx5qGJu45eH2T3Gw - secret key

// 6LdmsvIfAAAAAMdy31cmaXU4ZKaXcig1yJqr3-VR - site key
