import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/login",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/register",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/forgotPassword",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/resetPassword",
    method: "post",
    data,
  });
}

export async function userEmailActivation(data) {
  return ApiService.fetchData({
    url: "/confirm-mail",
    method: "post",
    data,
  });
}

export async function resetPasswordVerification(data) {
  return ApiService.fetchData({
    url: "/resetPassword",
    method: "get",
    params: data,
  });
}

export async function fiatRequestVerify(data) {
  return ApiService.fetchData({
    url: "/fiatWithdraw",
    method: "patch",
    data,
  });
}
export async function coinRequestVerify(data) {
  return ApiService.fetchData({
    url: "/coinWithdraw",
    method: "patch",
    data: data,
  });
}

export async function apiCheckUserName(data) {
  return ApiService.fetchData({
    url: "/checkUserName",
    method: "post",
    data,
  });
}
