import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import mockServer from "./mock";
import "./locales";
import SocketContext from "./lib/SocketContext";
import { socket } from "./configs/socketConnectivity";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/i18next/i18n";
const environment = process.env.NODE_ENV;

// if (environment !== 'production' && appConfig.enableMock) {
// 	mockServer({ environment })
// }

// mockServer({ environment });

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <SocketContext.Provider value={{ socket }}>
              <Theme>
                <Layout />
              </Theme>
            </SocketContext.Provider>
          </BrowserRouter>
        </PersistGate>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
